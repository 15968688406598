








/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import { Hive } from '@/types';
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class HiveHoneycombs extends Vue {
  @Prop(Object) readonly hive!: Hive

  mounted() {
    console.log('HiveHoneycombs: mounted()'); // eslint-disable-line no-console
  }

  get text(): string {
    return this.hive.honeycombsAvailable === 0
      ? `Nessun favo su ${this.hive.honeycombsCount} disponibile`
      : `${this.hive.honeycombsAvailable} favi su ${this.hive.honeycombsCount} disponibili`;
  }

  get textClass(): string {
    if (this.hive.honeycombsAvailable === 0) return 'red--text font-weight-bold';
    return 'green--text';
  }
}
